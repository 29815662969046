import { FullStory, init, isInitialized } from "@fullstory/browser";
import { getEnv, Env } from "../env";

const ORG_ID: Record<Env, string> = {
  production: "o-1ED2V5-na1",
  staging: "o-1ED2V5-na1",
};

const initFullstory = () => {
  const env = getEnv();
  if (env === "production" || env === "staging") {
    init(
      {
        orgId: ORG_ID[env],
        recordCrossDomainIFrames: true,
      },
      ({ sessionUrl }) => {
        console.log(`Fullstory Session Auth: ${sessionUrl}`);
      }
    );
  }
};

const sendTrackingEvent = (
  eventName: string,
  properties: { [key: string]: any }
) => {
  if (isInitialized()) {
    FullStory("trackEvent", { name: eventName, properties });
  }
};

export { initFullstory, sendTrackingEvent };
