import Rollbar from "rollbar";

const rollbarConfig: Rollbar.Configuration = {
  accessToken: process.env.REACT_APP_ROLLBAR_KEY,
  captureUncaught: true,
  captureUnhandledRejections: true,
  ignoredMessages: [/Request failed with status code 401/g],
  enabled:
    process.env.REACT_APP_ENV_NAME === "production" ||
    process.env.REACT_APP_ENV_NAME === "staging",
  payload: {
    environment: process.env.REACT_APP_ENV_NAME,
    context: "UI",
    client: {
      javascript: {
        guess_uncaught_frames: true,
        source_map_enabled: true,
      },
    },
  },
};

export { rollbarConfig };
