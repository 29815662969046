import axios, { AxiosError } from "axios";
import type { Opaque } from "type-fest";
import { BASE_URLS } from "config";
import { getEnv } from "env";

type SfdcToken = Opaque<string>;
type ErrorResponseData = {
  errorCode: string;
  message: string;
};

const determineErrorFromResponseData = (e: AxiosError) => {
  const responseData = e.response?.data as ErrorResponseData;
  if (responseData) {
    if (responseData.message.includes("INVALID_LOGIN")) {
      throw new Error("Incorrect username or password.", { cause: e });
    } else if (
      responseData.message
        .toLowerCase()
        .includes("user is not assigned to the client application")
    ) {
      throw new Error(
        "Logging in with Okta is not yet available to your user group. Please continue to log in with Salesforce.",
        { cause: e }
      );
    }
  }
};

const getToken = async (username: string, password: string) => {
  const env = getEnv();
  let response;
  try {
    response = await axios.post(
      `${BASE_URLS.AUTH_SERVICE[env]}/login/credentials`,
      {
        username,
        password,
      }
    );
  } catch (e) {
    const error = e as AxiosError;
    determineErrorFromResponseData(error);
    throw e;
  }

  return response.data as { token: SfdcToken; tokenType: TokenType };
};

export type { SfdcToken };
export { getToken };
