type Env = "staging" | "production";
type ENV_NAME = "staging" | "production";

const getEnv = (): Env => {
  switch (process.env.REACT_APP_ENV_NAME) {
    case "production":
    case "staging":
      return process.env.REACT_APP_ENV_NAME as ENV_NAME;
    default:
      return "staging";
  }
};

export type { Env };
export { getEnv };
