import { Env } from "env";

const BREAKPOINT = {
  MOBILE: "480px",
  TABLET: "1700px",
} as const;

const BASE_URLS = {
  AUTH_SERVICE: {
    staging: "https://gateway.sunrun.com/majstg-splat-auth",
    production: "https://gateway.sunrun.com/splat-auth",
  },
  CLIENT: {
    staging: "https://staging.sunrunone.com",
    production: "https://sunrunone.com",
  },
  HYBRID_AUTH: {
    staging: "https://gateway.sunrun.com/majstg-hybrid-auth",
    production: "https://gateway.sunrun.com/hybrid-auth",
  },
  OKTA: {
    staging: "https://sunrun.okta.com/oauth2",
    production: "https://sunrun.okta.com/oauth2",
  },
  SPLAT: {
    staging: "https://majstg-go.sunrun.com",
    production: "https://go.sunrun.com",
  },
  D2H_OKTA_APP: {
    staging:
      "https://sunrun.oktapreview.com/home/salesforce/0oae8c9ktSKkwjPRu1d6/46?fromHome=true",
    production:
      "https://sunrun.okta.com/home/salesforce/0oaqxfccr4AgQAd7p0x7/46?fromHome=true",
  },
  FUSION_OKTA_APP: {
    staging:
      "https://sunrun.oktapreview.com/home/salesforce/0oasjxzoa4sRe45iL1d6/46?fromHome=true",
    production:
      "https://sunrun.okta.com/home/salesforce/0oarqr7ms0jzWt1QM0x7/46?fromHome=true",
  },
} as const satisfies Record<string, Record<Env, string>>;

const OKTA = {
  CLIENT_ID: {
    staging: "0oay9pvg3wn5Cbzn40x7",
    production: "0oaydjpe0en0uiwPT0x7",
  },
  AUTH_SERVER: {
    staging: "ausy9pue3sQgo39rx0x7",
    production: "ausydjuwwyqP0WcNh0x7",
  },
} as const satisfies Record<string, Record<Env, string>>;

export { BASE_URLS, BREAKPOINT, OKTA };
