import styled from "@emotion/styled/macro";
import {
  Button,
  LoadingIndicator,
  OTPInput,
  Snackbar,
  TextField,
  Typography,
} from "@sunrun/experience-ui-components";
import { useCallback, useState } from "react";
import { SubmitHandler, useController, useForm } from "react-hook-form";
import { useRollbar } from "@rollbar/react";
import { TokenState } from "services/jwtCache";
import {
  requestLoginCode,
  validateLoginCode,
} from "services/retailThirdPartyLogin";
import { formatPhoneNumber } from "utils/format-phone-number";

type Inputs = {
  email?: string;
  phone?: string;
};

type RetailThirdPartyFormProps = {
  onLogin: (tokenState: TokenState) => void;
};

const RetailThirdPartyForm = ({ onLogin }: RetailThirdPartyFormProps) => {
  const rollbar = useRollbar();
  const [isEmailMode, setIsEmailMode] = useState(true);
  const [isCodeMode, setIsCodeMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [code, setCode] = useState<string | undefined>(undefined);
  const {
    control,
    watch,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const { field: phoneField } = useController({
    control,
    name: "phone",
    rules: {
      minLength: {
        value: 14,
        message: "Please enter a valid phone number",
      },
    },
  });

  const email = watch("email");
  const phone = watch("phone");

  const disableSendCode = isEmailMode ? !email : !phone;

  const _onSubmit: SubmitHandler<Inputs> = useCallback(
    async (values) => {
      setLoading(true);
      try {
        const response = await requestLoginCode(values.email, values.phone);
        setSession(response.session);
        setIsCodeMode(true);
      } catch (error: any) {
        rollbar.error(error);
        console.error(error);
        setErrorMessage(
          error?.message ?? "Something went wrong. Please try again."
        );
      } finally {
        setLoading(false);
      }
    },
    [rollbar]
  );

  const _onSubmitCode = useCallback(async () => {
    setLoading(true);

    if (!code || !session) {
      // Should never happen
      throw new Error("Code and session are required");
    }

    try {
      const response = await validateLoginCode({ code, session, email, phone });

      onLogin({
        token: response.hybridToken,
        tokenType: "cognito",
        source: "one",
      });
    } catch (error: any) {
      rollbar.error(error);
      console.error(error);
      const errorMessage =
        error.response?.data?.message?.message || error.response?.data?.message;
      setErrorMessage(
        errorMessage ?? "Invalid code entered. Try again or resend code."
      );
    } finally {
      setLoading(false);
    }
  }, [code, email, onLogin, phone, rollbar, session]);

  return (
    <>
      <Form id="retail-third-party" onSubmit={handleSubmit(_onSubmit)}>
        <Fieldset $hide={!isEmailMode || isCodeMode}>
          <TextField
            label="Email"
            placeholder="Enter email"
            disabled={loading}
            isError={!!errors?.email}
            helperText={errors?.email?.message}
            {...register("email", {
              shouldUnregister: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Please enter a valid email",
              },
            })}
          />
        </Fieldset>
        <Fieldset $hide={isEmailMode || isCodeMode}>
          <TextField
            type="tel"
            name="phone"
            label="Phone"
            disabled={loading}
            ref={phoneField.ref}
            value={phoneField.value}
            placeholder="Enter phone"
            isError={!!errors?.phone}
            helperText={errors?.phone?.message}
            onChange={(event) =>
              phoneField.onChange(formatPhoneNumber(event.target.value))
            }
          />
        </Fieldset>

        {isCodeMode && (
          <OTPFieldset>
            <label>
              <Typography size="small" color="#6F6D68">
                Code
              </Typography>
            </label>
            <div>
              <OTPInput
                autoFocus
                length={6}
                onComplete={(newCode) => setCode(newCode)}
              />
            </div>
          </OTPFieldset>
        )}

        <ButtonWrapper>
          <Button
            type="button"
            color="link"
            onClick={() => {
              setIsEmailMode((prev) => !prev);
              setIsCodeMode(false);
              setCode(undefined);
              reset();
            }}
          >
            Log in by {isEmailMode ? "phone" : "email"} instead
          </Button>

          {isCodeMode && (
            <Button
              type="button"
              color="link"
              onClick={() => {
                _onSubmit({ email, phone });
              }}
            >
              Resend code
            </Button>
          )}
        </ButtonWrapper>

        <LoginWrapper>
          {isCodeMode ? (
            <Button
              type="button"
              disabled={!code || loading}
              onClick={_onSubmitCode}
            >
              {loading ? (
                <Loading>
                  <LoadingIndicator showLoadingMessage={false} />
                </Loading>
              ) : (
                "Log in"
              )}
            </Button>
          ) : (
            <Button type="submit" disabled={disableSendCode || loading}>
              {loading ? (
                <Loading>
                  <LoadingIndicator showLoadingMessage={false} />
                </Loading>
              ) : (
                "Send code"
              )}
            </Button>
          )}
        </LoginWrapper>
      </Form>
      {!!errorMessage && (
        <Snackbar
          open={!!errorMessage}
          message={errorMessage}
          type="error"
          onClose={() => {
            setErrorMessage(undefined);
          }}
        />
      )}
    </>
  );
};

const Form = styled.form`
  margin-top: 6px;
`;

const Fieldset = styled.fieldset<{ $hide: boolean }>`
  display: ${(props) => (props.$hide ? "none" : "block")};
  border: none;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 24px;
`;

const OTPFieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 24px;

  > label {
    display: block;
    margin-bottom: 10px;
  }

  input {
    margin: 0;
  }

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const LoginWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Loading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export { RetailThirdPartyForm };
