import styled from "@emotion/styled/macro";
import { Typography } from "@sunrun/experience-ui-components";
import { useCallback } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useRollbar } from "@rollbar/react";
import type { State } from "./LoginComponent";
import { getHybridToken } from "services/hybridAuthService";
import { TokenState } from "services/jwtCache";
import { getToken } from "services/splatAuthService";

type Inputs = {
  username: string;
  password: string;
};

type SfdcFormProps = {
  onError: (error: unknown) => void;
  onLogin: (tokenState: TokenState) => void;
  onSubmit: () => void;
  state: State;
};
const SfdcForm = ({ onError, onLogin, onSubmit, state }: SfdcFormProps) => {
  const rollbar = useRollbar();
  const { handleSubmit, register, reset } = useForm<Inputs>();
  const _onSubmit: SubmitHandler<Inputs> = useCallback(
    async (values) => {
      onSubmit();
      try {
        const { token, tokenType } = await getToken(
          values.username,
          values.password
        );
        const { hybridToken } = await getHybridToken(tokenType, token);
        onLogin({
          token: hybridToken,
          tokenType,
          source: "one",
        });
        reset();
      } catch (error: any) {
        rollbar.error(error);
        console.error(error);
        onError(error);
      }
    },
    [onError, onLogin, onSubmit, reset, rollbar]
  );
  return (
    <Form id="partner-form" onSubmit={handleSubmit(_onSubmit)}>
      <Fieldset>
        <label>
          <Typography size="small" color="#6F6D68">
            Username
          </Typography>
        </label>
        <input
          {...register("username")}
          placeholder="Enter username"
          disabled={state.type === "loading"}
        />
      </Fieldset>
      <Fieldset>
        <label>
          <Typography size="small" color="#6F6D68">
            Password
          </Typography>
        </label>
        <input
          {...register("password")}
          type="password"
          placeholder="Enter password"
          disabled={state.type === "loading"}
        />
      </Fieldset>
      <ResetPassword>
        <a
          href="https://majstg-sunruncommunity.cs252.force.com/partner/PartnerPortalForgotPassword"
          target="_blank"
          rel="noreferrer"
        >
          Reset Password
        </a>
      </ResetPassword>
    </Form>
  );
};

const Form = styled.form`
  margin-top: 6px;
`;
const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin-top: 20px;
  > label {
    display: block;
  }
  input {
    margin-top: 12px;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 1rem;
    background: #ffffff;
    border: 1px solid #a3a09a;
    display: block;
    width: 100%;
  }
`;
const ResetPassword = styled.div`
  display: none; // Not currently being used
  margin-top: 12px;
`;

export { SfdcForm };
