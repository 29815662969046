// Ripped from cleave-zen
type DelimiterType = string;
type BlocksType = number[];

interface StripDelimitersProps {
  value: string;
  delimiters: DelimiterType[];
}

interface GetFormattedValueProps {
  value: string;
  blocks: BlocksType;
  delimiter?: DelimiterType;
  delimiters?: DelimiterType[];
  delimiterLazyShow?: boolean;
}

interface FormatGeneralOptions {
  blocks: BlocksType;
  delimiterLazyShow?: boolean;
  delimiter?: DelimiterType;
  delimiters?: DelimiterType[];
  prefix?: string;
  numericOnly?: boolean;
  uppercase?: boolean;
  lowercase?: boolean;
}

interface GetPrefixStrippedValueProps {
  value: string;
  prefix: string;
  tailPrefix: boolean;
}

const stripNonNumeric = (value: string): string => value.replace(/[^\d]/g, "");

const getDelimiterRegexByDelimiter = (delimiter: string): RegExp =>
  new RegExp(delimiter.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1"), "g");

const stripDelimiters = ({
  value,
  delimiters,
}: StripDelimitersProps): string => {
  delimiters.forEach((current: DelimiterType) => {
    current.split("").forEach((letter) => {
      value = value.replace(getDelimiterRegexByDelimiter(letter), "");
    });
  });

  return value;
};

const getFormattedValue = ({
  value,
  blocks,
  delimiter = "",
  delimiters = [],
  delimiterLazyShow = false,
}: GetFormattedValueProps): string => {
  let result = "";
  let valueRemaining = value;
  let currentDelimiter = "";

  blocks.forEach((length: number, index: number) => {
    if (valueRemaining.length > 0) {
      const sub = valueRemaining.slice(0, length);
      const rest = valueRemaining.slice(length);

      if (delimiters.length > 0) {
        currentDelimiter =
          delimiters[delimiterLazyShow ? index - 1 : index] ?? currentDelimiter;
      } else {
        currentDelimiter = delimiter;
      }

      if (delimiterLazyShow) {
        if (index > 0) {
          result += currentDelimiter;
        }

        result += sub;
      } else {
        result += sub;

        if (sub.length === length && index < blocks.length - 1) {
          result += currentDelimiter;
        }
      }

      // update remaining string
      valueRemaining = rest;
    }
  });

  return result;
};

const stripPrefix = ({
  value,
  prefix,
  tailPrefix,
}: GetPrefixStrippedValueProps): string => {
  const prefixLength: number = prefix.length;

  // No prefix
  if (prefixLength === 0) {
    return value;
  }

  // Value is prefix
  if (value === prefix && value !== "") {
    return "";
  }

  // result prefix string does not match pre-defined prefix
  if (value.slice(0, prefixLength) !== prefix && !tailPrefix) {
    return "";
  } else if (value.slice(-prefixLength) !== prefix && tailPrefix) {
    return "";
  }

  // No issue, strip prefix for new value
  return tailPrefix ? value.slice(0, -prefixLength) : value.slice(prefixLength);
};

const formatGeneral = (
  value: string,
  options: FormatGeneralOptions
): string => {
  const {
    blocks,
    delimiter = "",
    delimiters = [],
    delimiterLazyShow = false,
    prefix = "",
    numericOnly = false,
    uppercase = false,
    lowercase = false,
  } = options;

  const tailPrefix: boolean = false; // This is too buggy to be true

  if (delimiter.length > 0) {
    delimiters.push(delimiter);
  }

  // strip delimiters
  value = stripDelimiters({
    value,
    delimiters,
  });

  // strip prefix
  value = stripPrefix({
    value,
    prefix,
    tailPrefix,
  });

  // strip non-numeric characters
  value = numericOnly ? stripNonNumeric(value) : value;

  // convert case
  value = uppercase ? value.toUpperCase() : value;
  value = lowercase ? value.toLowerCase() : value;

  // prevent from showing prefix when no immediate option enabled with empty input value
  if (prefix.length > 0) {
    if (tailPrefix) {
      value = value + prefix;
    } else {
      value = prefix + value;
    }
  }

  // apply blocks
  value = getFormattedValue({
    value,
    blocks,
    delimiter,
    delimiters,
    delimiterLazyShow,
  });

  return value;
};

const unformatGeneral = (
  value: string,
  options: Pick<FormatGeneralOptions, "delimiter" | "delimiters">
): string => {
  const { delimiter = "", delimiters = [] } = options;
  return stripDelimiters({ value, delimiters: [...delimiters, delimiter] });
};

const formatPhoneNumber = (input: string) =>
  formatGeneral(input, {
    blocks: [0, 3, 3, 4],
    delimiters: ["(", ") ", "-"],
    delimiterLazyShow: true,
    numericOnly: true,
  });

const unformatPhoneNumber = (input: string) =>
  unformatGeneral(input, {
    delimiters: ["(", ") ", "-"],
  });

export { formatPhoneNumber, unformatPhoneNumber };
