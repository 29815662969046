import styled from "@emotion/styled/macro";
import { useState } from "react";
import {
  Button,
  Select,
  tokens,
  LoadingIndicator,
} from "@sunrun/experience-ui-components";
import { handleTokenStorageEvent, setJwtCache } from "services/jwtCache";
import { postMessage } from "messages";
import { getEnv } from "env";
import { BASE_URLS } from "config";

const INSIDE = "inside";
const STREET = "street";
const FUSION = "fusion";
const CEA = "cea";

type LoginTypes = typeof INSIDE | typeof STREET | typeof FUSION | typeof CEA;

const OktaForm = () => {
  const [isPending, setIsPending] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const login = async () => {
    setIsPending(true);
    const authCallback = handleTokenStorageEvent();
    window.open("/okta");
    const tokenState = await authCallback;
    setJwtCache(tokenState);
    setIsPending(false);
    postMessage({
      type: "EVENT:AUTH_TOKEN",
      source: "Auth",
      payload: {
        tokenType: "okta",
        token: tokenState!.token,
      },
    });
  };
  const env = getEnv();
  let defaultLogin = INSIDE;
  const storedLogin = localStorage.getItem("lastSplatLogin");
  if (storedLogin) {
    defaultLogin = storedLogin;
  }
  const [loginType, setLoginType] = useState(defaultLogin);
  const doSplatLogin = () => {
    localStorage.setItem("lastSplatLogin", loginType);
    switch (loginType) {
      case INSIDE:
        postMessage({
          type: "COMMAND:LOGIN_REDIRECT",
          source: "Auth",
          payload: { url: `${BASE_URLS.AUTH_SERVICE[env]}/login/oauth` },
        });
        break;
      case STREET:
        postMessage({
          type: "COMMAND:LOGIN_REDIRECT",
          source: "Auth",
          payload: { url: `${BASE_URLS.D2H_OKTA_APP[env]}` },
        });
        break;
      case FUSION:
        postMessage({
          type: "COMMAND:LOGIN_REDIRECT",
          source: "Auth",
          payload: { url: `${BASE_URLS.FUSION_OKTA_APP[env]}` },
        });
        break;
      case CEA:
        login();
        break;
    }
  };

  const options = [
    { value: INSIDE, label: "Sunrun Field & Inside Sales" },
    { value: STREET, label: "Street" },
    { value: FUSION, label: "Fusion" },
    { value: CEA, label: "Retail" },
  ];

  const initialOption =
    options.find((option) => option.value === loginType) || options[0];

  return (
    <SignOn>
      {isPending && (
        <LoadingIndicator
          color="black"
          height={200}
          width={200}
          showLoadingMessage={false}
        />
      )}
      {/* {!isPending && <button onClick={login}>Sign in with OKTA</button>} */}
      <Select
        value={initialOption.value}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          setLoginType(event.target.value as LoginTypes);
        }}
        onBlur={() => {}}
        options={options}
      />

      {!isPending && (
        <Button size="md" onClick={doSplatLogin}>
          Log In
        </Button>
      )}
    </SignOn>
  );
};

const SignOn = styled.div`
  margin-top: 38px;
  margin-right: 4px;
  > .sr-select-container {
    margin-bottom: 16px;
    button {
      color: ${tokens.TINTS_OFF_WHITE_20};
    }
  }
  > button {
    background: #181e3a;
    border-radius: 800px;
    border: none;
    cursor: pointer;
    padding: 18px;
    position: relative;
    width: 100%;
    &:disabled {
      cursor: default;
      background: #181e3a50;
    }
  }
`;

export { OktaForm };
