import assert from "assert";
import axios from "axios";
import { getJwtCache, HybridToken } from "./jwtCache";
import type { OktaToken } from "./oktaService";
import type { SfdcToken } from "./splatAuthService";
import { BASE_URLS, OKTA } from "config";
import { getEnv } from "env";

const getHybridToken = async (
  tokenType: TokenType,
  token: OktaToken | SfdcToken | HybridToken,
  masqueradeContactId?: string
) => {
  assert(token, "No token provided!");
  const env = getEnv();
  const response = await axios.post(
    `${BASE_URLS.HYBRID_AUTH[env]}/hybridTokens`,
    {
      tokenType,
      token,
      authServer:
        tokenType === "okta" || tokenType === "masquerade"
          ? OKTA.AUTH_SERVER[env]
          : undefined,
      loadSfdcContact: tokenType === "okta" || tokenType === "masquerade",
      masqueradeContactId,
    }
  );
  return response.data as { hybridToken: HybridToken };
};

const verifyJwt = async () => {
  // TODO: userProfile is slow, find a faster way to validate JWT
  return getUserProfile();
};

const getUserProfile = async () => {
  const env = getEnv();
  const jwtState = getJwtCache();
  if (!jwtState) throw new Error("No JWT");
  await axios.get(`${BASE_URLS.HYBRID_AUTH[env]}/userProfile`, {
    headers: {
      Authorization: `Bearer ${jwtState.token}`,
    },
  });
};

export { getHybridToken, getUserProfile, verifyJwt };
