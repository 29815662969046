import type { Opaque } from "type-fest";
import cookie from "cookie";

const CACHE_KEY = "token";

type HybridToken = Opaque<string>;
type TokenState = {
  token: HybridToken;
  tokenType: TokenType;
  source: "one" | "splat";
  /**
   * Unix Timestamp
   * @example 1674748642
   */
  expiration?: number;
};

const clearJwtCache = () => {
  localStorage.removeItem(CACHE_KEY);
};

const getJwtCache = (): TokenState | undefined => {
  try {
    const c = cookie.parse(document.cookie);
    try {
      const state = JSON.parse(c?.token) as TokenState;
      if (state.token && state.tokenType) {
        document.cookie = "token=; Max-Age=-99999999;";
        setJwtCache({ ...state, source: "splat" });
        return state;
      }
    } catch (e) {}
    return JSON.parse(localStorage.getItem(CACHE_KEY) ?? "");
  } catch (e) {
    return;
  }
};

const setJwtCache = (state: TokenState): void => {
  localStorage.setItem(CACHE_KEY, JSON.stringify(state));
};

const handleTokenStorageEvent = (): Promise<TokenState> => {
  return new Promise((resolve, reject) => {
    const handler = (event: StorageEvent) => {
      if (event.key === CACHE_KEY) {
        resolve(JSON.parse(event.newValue!));
        window.removeEventListener("storage", handler);
      }
    };
    window.addEventListener("storage", handler);
  });
};

export type { TokenState, HybridToken };
export { clearJwtCache, getJwtCache, handleTokenStorageEvent, setJwtCache };
