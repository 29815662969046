import { AmplifyPost } from "@sunrun/sales-experience-shared/amplify-api-wrapper";
import {
  GetLoginCodeResponse,
  ValidateLoginCodeProps,
  ValidateLoginCodeResponse,
} from "../../amplify/backend/function/retailThirdPartyLogin/ts/public/retailThirdPartyLoginTypes";
import { logger } from "./logger";

const requestLoginCode = async (
  email?: string,
  phone?: string
): Promise<GetLoginCodeResponse> => {
  return AmplifyPost(
    "Request Login Code",
    "SalesExpAuthApi",
    `/retail-third-party-login/requestLoginCode`,
    {
      body: { email, phone },
    },
    logger
  );
};

const validateLoginCode = async ({
  code,
  session,
  email,
  phone,
}: ValidateLoginCodeProps): Promise<ValidateLoginCodeResponse> => {
  return AmplifyPost(
    "Validate Login Code",
    "SalesExpAuthApi",
    `/retail-third-party-login/validateLoginCode`,
    {
      body: {
        email,
        phone,
        code,
        session,
      },
    },
    logger
  );
};

export { requestLoginCode, validateLoginCode };
