import type { Message } from "./Message";

const postMessage = (message: Message) => {
  window.parent.postMessage(message, "*");
};

const logMessage = (message: Message) => {
  switch (message.type) {
    case "COMMAND:CHANGE_URL":
    case "COMMAND:LOGIN_REDIRECT":
    case "COMMAND:LOGOUT":
    case "COMMAND:MASQUERADE":
    case "EVENT:AUTH_TOKEN":
    case "EVENT:READY":
    case "QUERY:AUTH_TOKEN":
      console.log(
        "📬",
        message.source,
        message.type,
        "payload" in message ? message.payload : undefined
      );
      break;
    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const never: never = message;
    }
  }
};

export { logMessage, postMessage };
