import "index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  createMemoryRouter,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import { Amplify } from "aws-amplify";
import { AppThemeProvider } from "@sunrun/experience-ui-components";
import { ErrorBoundary, Provider, RollbarContext } from "@rollbar/react";
import { rollbarConfig } from "./config/rollbar";
import awsconfig from "./aws-exports";
import { LoginPage } from "components/pages/LoginPage";
import { OktaRedirect } from "components/pages/OktaRedirect";
import { SplatLoginPage } from "components/pages/SplatLoginPage";
import { initFullstory } from "services/fullStory";

initFullstory();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

Amplify.configure(awsconfig);

const routes: Array<RouteObject> = [
  {
    path: "/okta",
    element: (
      <RollbarContext context={"/okta"}>
        <OktaRedirect />
      </RollbarContext>
    ),
  },
  {
    path: "/",
    element: (
      <RollbarContext context={"/root"}>
        <LoginPage />
      </RollbarContext>
    ),
  },
  {
    path: "/splat-login",
    element: (
      <RollbarContext context={"/splat-login"}>
        <SplatLoginPage />
      </RollbarContext>
    ),
  },
];

const router = createMemoryRouter(routes, {
  initialEntries: [window.location],
  initialIndex: 0,
});

root.render(
  <React.StrictMode>
    <AppThemeProvider>
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          <RouterProvider router={router} />
        </ErrorBoundary>
      </Provider>
    </AppThemeProvider>
  </React.StrictMode>
);
