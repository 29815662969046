import OktaAuth from "@okta/okta-auth-js";
import type { Opaque } from "type-fest";
import { BASE_URLS, OKTA } from "config";
import { getEnv } from "env";

type OktaToken = Opaque<string>;

let oktaAuth: OktaAuth;
const getOktaAuth = () => {
  const env = getEnv();
  if (oktaAuth) return oktaAuth;
  oktaAuth = new OktaAuth({
    issuer: `${BASE_URLS.OKTA[env]}/${OKTA.AUTH_SERVER[env]}`,
    clientId: OKTA.CLIENT_ID[env],
    redirectUri: window.location.origin,
    scopes: ["openid", "profile", "email"],
  });
  return oktaAuth;
};

const getToken = async (): Promise<{
  token: OktaToken;
  expiration: number;
}> => {
  const response = await getOktaAuth().token.parseFromUrl();
  return {
    token: response.tokens.accessToken!.accessToken as OktaToken,
    expiration: response.tokens.accessToken!.expiresAt,
  };
};

export type { OktaToken };
export { getToken, getOktaAuth };
