import {
  Tabs,
  Typography,
  LoadingIndicator,
  Button,
} from "@sunrun/experience-ui-components";
import { useCallback, useState } from "react";
import styled from "@emotion/styled/macro";
import classNames from "classnames";
import { SfdcForm } from "./SfdcForm";
import { RetailThirdPartyForm } from "./RetailThirdPartyForm";
import salesRep from "assets/sales-rep.png";
import sunrunLogo from "assets/sunrun-logo.svg";
import xIcon from "assets/x.svg";
import { postMessage } from "messages";
import { OktaForm } from "components/organisms/OktaForm";
import { setJwtCache, TokenState } from "services/jwtCache";
import { BASE_URLS, BREAKPOINT } from "config";
import { getEnv } from "env";

type State =
  | {
      type: "ready";
    }
  | {
      type: "loading";
    }
  | {
      type: "error";
      error: unknown;
    };

const LoginComponent = () => {
  const [state, setState] = useState<State>({ type: "ready" });
  const [tabsIndex, setTabsIndex] = useState(0);

  const env = getEnv();
  const isProduction = env === "production";

  const onError = useCallback(
    (error: unknown) => {
      setState({ type: "error", error });
    },
    [setState]
  );
  const onSubmit = useCallback(() => {
    setState({ type: "loading" });
  }, [setState]);
  const onLogin = useCallback((tokenState: TokenState) => {
    setJwtCache(tokenState);
    postMessage({
      type: "EVENT:AUTH_TOKEN",
      source: "Auth",
      payload: {
        tokenType: tokenState.tokenType,
        token: tokenState.token,
      },
    });
    setState({ type: "ready" });
  }, []);
  const onClearError = useCallback(() => {
    setState({ type: "ready" });
  }, [setState]);
  const loginPartnerPortal = useCallback(() => {
    postMessage({
      type: "COMMAND:LOGIN_REDIRECT",
      source: "Auth",
      payload: {
        url: `${BASE_URLS.AUTH_SERVICE[env]}/login/oauth?isPartner=true`,
      },
    });
  }, [env]);
  return (
    <Layout>
      <ColumnSplash>
        <HeroArea>
          <HeroGradient1 />
          <HeroGradient2 />
          <Hero src={salesRep} alt="Sales Rep Hero" />
        </HeroArea>
        <FloatingLogo src={sunrunLogo} alt="Sunrun Logo" />
      </ColumnSplash>
      <ColumnForm>
        <Logo src={sunrunLogo} alt="Sunrun Logo" />
        <Welcome>
          <Typography component="h1" variant="h1">
            <Line>Welcome to</Line>
            <Line>Sunrun ONE</Line>
          </Typography>
        </Welcome>
        <LoginTypeSelector>
          <Instructions>
            <Typography component="p" color="#474643">
              Select your account type to log in.
            </Typography>
          </Instructions>
          <Tabs
            onSelectTab={(tabIndex) => setTabsIndex(tabIndex)}
            initialTabIndex={tabsIndex}
            tabs={["Sunrun", "Partner", "Retail Third Party"]}
            contents={[
              <OktaForm />,
              <>
                <PartnerPortalButton>
                  <Button onClick={loginPartnerPortal}>Preferred log in</Button>
                </PartnerPortalButton>
                {!isProduction && (
                  <>
                    <ClassicHeader>
                      <Typography component="h1">
                        Classic login (going away soon)
                      </Typography>
                    </ClassicHeader>
                    <SfdcForm
                      state={state}
                      onError={onError}
                      onLogin={onLogin}
                      onSubmit={onSubmit}
                    />
                    <SignOn
                      className={classNames({
                        "--loading": state.type === "loading",
                      })}
                    >
                      <button
                        form="partner-form"
                        type="submit"
                        disabled={state.type === "loading"}
                      >
                        <Typography align="center">Classic log in</Typography>
                        {state.type === "loading" && (
                          <Loading>
                            <LoadingIndicator showLoadingMessage={false} />
                          </Loading>
                        )}
                      </button>
                    </SignOn>
                  </>
                )}
              </>,
              <RetailThirdPartyForm onLogin={onLogin} />,
            ]}
            testIds={["auth-sunrun-tab", "auth-partner-tab"]}
          />
        </LoginTypeSelector>
        {state.type === "error" && (
          <ErrorMessage>
            <Typography color="#9E1E19">
              {state.error instanceof Error && state.error.message}
            </Typography>
            <ErrorCloseButton src={xIcon} onClick={onClearError} />
          </ErrorMessage>
        )}
        <Support>
          <Typography color="#6F6D68">
            Need help? Visit our{" "}
            <a href="https://help.sunrun.com" target="_blank" rel="noreferrer">
              Support Guide
            </a>
          </Typography>
        </Support>
      </ColumnForm>
    </Layout>
  );
};

const ColumnSplash = styled.div`
  overflow: hidden;
`;

const whenSingleColumn = () => {
  return (style: TemplateStringsArray | String) =>
    `@media only screen and ((max-width: ${BREAKPOINT.MOBILE}) or ((min-width: ${BREAKPOINT.MOBILE}) and (max-width: ${BREAKPOINT.TABLET}) and (orientation: portrait))) {
      ${style}
    }`;
};

const whenTwoColumn = () => {
  return (style: TemplateStringsArray | String) =>
    `@media only screen and ((min-width: ${BREAKPOINT.TABLET}) or ((min-width: ${BREAKPOINT.MOBILE}) and (max-width: ${BREAKPOINT.TABLET}) and (orientation: landscape))) {
      ${style}
    }`;
};

const ColumnForm = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  ${whenSingleColumn()`{
    padding: 28px 16px;
  }`}
  ${whenTwoColumn()`{
    padding: 48px 64px 28px calc((100vw - (128px + 16px * 11)) / 12 + 16px);
  }`}
`;

const Layout = styled.div`
  background: linear-gradient(
    192.65deg,
    #dbe9ff 7.2%,
    #dbe9ff 30.83%,
    #f8ffff 54.45%,
    #fffcf5 104.68%
  );

  display: grid;
  height: 100vh;
  width: 100vw;
  grid-template-columns: 1fr 1fr;

  ${whenSingleColumn()`{
    grid-template-columns: 0 1fr;
  }`};
`;

const HeroArea = styled.div`
  height: 100%;
`;

const HeroGradient1 = styled.div`
  position: absolute;
  left: 0;
  right: 75%;
  top: 0%;
  bottom: 70%;

  background: linear-gradient(33.15deg, #fffbf0 32.34%, #a5c9ff 126.71%);
  opacity: 0.38;
  transform: rotate(180deg);
  clip-path: polygon(0% 100%, 100% 100%, 100% 0%);

  ${whenSingleColumn()`{
    width: 0;
  }`};
`;

const HeroGradient2 = styled.div`
  position: absolute;
  left: 25%;
  right: 50%;
  top: 70%;
  bottom: 0%;

  background: linear-gradient(
    206.82deg,
    rgba(255, 251, 240, 0) 59.3%,
    #a5c9ff 116.27%
  );
  opacity: 0.36;
  clip-path: polygon(0% 100%, 100% 100%, 100% 0%);

  ${whenSingleColumn()`{
    width: 0;
  }`};
`;

const Hero = styled.img`
  height: 100%;

  ${whenSingleColumn()`{
    width: 0;
  }`};
`;

const FloatingLogo = styled.img`
  position: absolute;
  top: 48px;
  left: 41px;

  ${whenSingleColumn()`{
    display: none;
  }`};
`;

const Logo = styled.img`
  align-self: flex-start;
  ${whenTwoColumn()`{
    display: none;
  }`};
`;

const Welcome = styled.div`
  ${whenSingleColumn()`{
    margin-top: 36px;
  }`};
  ${whenTwoColumn()`{
    margin-top: 0;
  }`};
`;

const Line = styled.span`
  display: inline-block;
  margin-right: 0.25em;
`;

const LoginTypeSelector = styled.div`
  margin-top: 16px;

  max-width: 400px;
`;

const Instructions = styled.div`
  margin-bottom: 24px;
`;

const Loading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SignOn = styled.div`
  margin-top: 38px;
  ${whenSingleColumn()`{
    order: 3;
  }`};
  ${whenTwoColumn()`{
    order: 1;
  }`};
  * {
    color: white;
  }
  &.--loading > button > :not(${Loading}) {
    visibility: hidden;
  }
  > button {
    background: #181e3a;
    border-radius: 800px;
    border: none;
    cursor: pointer;
    padding: 18px;
    position: relative;
    width: 100%;
    &:disabled {
      cursor: default;
      background: #181e3a50;
    }
  }
`;

const Support = styled.div`
  margin-top: 42px;
  order: 2;
  flex-grow: 1;
  display: flex;
  align-items: end;
`;

const ErrorCloseButton = styled.img``;

const ErrorMessage = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  ${whenSingleColumn()`{
    padding: 24px;
    padding-right: 104px;
    bottom: 0;
  }`};
  ${whenTwoColumn()`{
    padding: 28px 64px;
    padding-right: calc((100vw - (128px + 16px * 11)) / 12 + 16px + 64px);
    top: 0;
  }`};
  background: #f8eeed;
  > ${ErrorCloseButton} {
    position: absolute;
    right: calc(64px + 10px);
    top: calc(50% - 10px);
  }
`;

const PartnerPortalButton = styled.div`
  margin-top: 1rem;
`;

const ClassicHeader = styled.div`
  margin-top: 1rem;
`;

export type { State };
export { LoginComponent };
