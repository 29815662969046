import { useSearchParams } from "react-router-dom";
import cookie from "cookie";
import { LoadingOverlay } from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";
import { useEffect, useState } from "react";
import { setJwtCache, HybridToken } from "services/jwtCache";
import { BASE_URLS } from "config";
import { getEnv } from "env";
import { verifyJwt } from "services/hybridAuthService";

const SplatLoginPage = () => {
  const [showError, setShowError] = useState(false);
  const [searchParams] = useSearchParams();
  const env = getEnv();
  const token = searchParams.get("token") as HybridToken;
  const tokenType = searchParams.get("tokenType");
  // This check is (mostly) for typing, we don't expect SPLAT to ever send any other type
  if (
    tokenType === "salesforce" ||
    tokenType === "okta" ||
    tokenType === "cognito"
  ) {
    setJwtCache({
      token,
      tokenType,
      source: "splat",
    });
  }
  useEffect(
    () => {
      verifyJwt()
        .then(() => {
          // NOTE: This cookie is to get around Safari's cross site tracking security
          document.cookie = cookie.serialize(
            "token",
            JSON.stringify({ token, tokenType })
          );
          window.location.href = `${BASE_URLS.CLIENT[env]}/transfer`;
        })
        .catch(() => {
          setShowError(true);
        });
    },
    // Intentionally blank to run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const logoutOfSplat = () => {
    window.location.href = BASE_URLS.SPLAT[env] + "/logout";
  };
  return (
    <Page>
      {!showError && <LoadingOverlay message="Sending you to One..." />}
      {showError && (
        <PageCenter>
          <div>
            Unfortunately, your login has expired. Please{" "}
            <InlineButton onClick={logoutOfSplat}>
              logout of Sales Platform
            </InlineButton>{" "}
            and try again.
          </div>
        </PageCenter>
      )}
    </Page>
  );
};

const Page = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #fffbf0;
`;

const PageCenter = styled.div`
  padding-top: 20vh;
  text-align: center;
`;

const InlineButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  color: blue;
`;

export { SplatLoginPage };
