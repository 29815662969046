import { useCallback, useEffect, useRef, useState } from "react";
import { useRollbar } from "@rollbar/react";
import { LoadingOverlay } from "@sunrun/experience-ui-components";
import { LoginComponent } from "components/organisms/LoginComponent";
import { Message, postMessage } from "messages";
import { clearJwtCache, getJwtCache, setJwtCache } from "services/jwtCache";
import { getHybridToken, verifyJwt } from "services/hybridAuthService";
import { getOktaAuth, getToken } from "services/oktaService";
import { BASE_URLS } from "config";
import { getEnv } from "env";

type Mode = "INACTIVE" | "LOGIN" | "OKTA_CALLBACK";
const oktaAuth = getOktaAuth();

const LoginPage = () => {
  const rollbar = useRollbar();
  const [mode, setMode] = useState<Mode>(
    oktaAuth.isLoginRedirect() ? "OKTA_CALLBACK" : "INACTIVE"
  );
  const validateJwt = useCallback(async () => {
    const cachedJwt = getJwtCache();
    if (!cachedJwt?.token) {
      setMode("LOGIN");
      return;
    }
    try {
      if (
        cachedJwt.expiration &&
        cachedJwt.expiration < new Date().valueOf() / 1000
      ) {
        clearJwtCache();
        setMode("LOGIN");
        return;
      }
      postMessage({
        type: "EVENT:AUTH_TOKEN",
        source: "Auth",
        payload: {
          tokenType: cachedJwt.tokenType,
          token: cachedJwt.token,
        },
      });
      setMode("INACTIVE");
      await verifyJwt();
    } catch (e: any) {
      rollbar.error(e);
      clearJwtCache();
      setMode("LOGIN");
      postMessage({
        type: "QUERY:AUTH_TOKEN",
        source: "Auth",
      });
    }
  }, [setMode, rollbar]);
  const messageHandler = useCallback(
    async (event: MessageEvent<Message>) => {
      if (!event?.data?.type) return;
      const message = event.data;
      switch (message.type) {
        case "COMMAND:LOGOUT": {
          //TODO: If Okta, also revoke session
          const cachedJwt = getJwtCache();
          clearJwtCache();
          if (cachedJwt?.source === "splat") {
            const env = getEnv();
            postMessage({
              type: "COMMAND:CHANGE_URL",
              source: "Auth",
              payload: {
                url: `${BASE_URLS.SPLAT[env]}/logout`,
              },
            });
          }
          setMode("LOGIN");
          break;
        }
        case "COMMAND:MASQUERADE": {
          const { masqueradeContactId } = message.payload;
          const cachedJwt = getJwtCache();
          if (!cachedJwt) {
            return;
          }
          const { hybridToken } = await getHybridToken(
            "masquerade",
            cachedJwt.token,
            masqueradeContactId
          );
          setJwtCache({
            token: hybridToken,
            tokenType: "okta",
            expiration: cachedJwt.expiration,
            source: "one",
          });
          postMessage({
            type: "EVENT:AUTH_TOKEN",
            source: "Auth",
            payload: {
              tokenType: "okta",
              token: hybridToken,
            },
          });
          break;
        }
        case "QUERY:AUTH_TOKEN": {
          setMode("INACTIVE");
          await validateJwt();
          break;
        }
      }
    },
    [validateJwt]
  );
  useEffect(() => {
    window.addEventListener("message", messageHandler);
    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, [messageHandler]);
  const alreadyRanReady = useRef(false);
  useEffect(() => {
    if (alreadyRanReady.current) return;
    alreadyRanReady.current = true;
    postMessage({
      source: "Auth",
      type: "EVENT:READY",
    });
  }, [alreadyRanReady]);
  const alreadyRanCallback = useRef(false);
  const oktaCallback = useCallback(async () => {
    if (mode !== "OKTA_CALLBACK") return;
    try {
      const tokenState = await getToken();
      const { hybridToken } = await getHybridToken("okta", tokenState.token);
      setJwtCache({
        token: hybridToken,
        tokenType: "okta",
        expiration: tokenState.expiration,
        source: "one",
      });
      window.close();
    } catch (e: any) {
      rollbar.error(e);
    }
  }, [mode, rollbar]);
  useEffect(() => {
    if (mode !== "OKTA_CALLBACK") return;
    if (alreadyRanCallback.current) return;
    alreadyRanCallback.current = true;
    oktaCallback();
  }, [mode, oktaCallback, alreadyRanCallback]);
  return (
    <>
      {mode === "LOGIN" && <LoginComponent />}
      {mode === "OKTA_CALLBACK" && (
        <LoadingOverlay message="Creating Session..." />
      )}
    </>
  );
};

export { LoginPage };
